import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed } from 'vue'
import { useSessionStorage, useStorage } from '@vueuse/core'
import { generateUUID } from '/@src/utils/common'
import * as SessionModel from '../models/sessionModel'
import { useSocketStore } from './socketStore'
import { type WarehouseRto } from '../models/warehouseModel'
import { tracker } from '../app'

export interface ISessionInfo {
  sessionId: string
  userId: number
  userName: string
  name: string
  email: string
  userType: string
  profileImg: string
  designation: string
  phone: string
  address: string
  defaultWarehouseId: number
  status: string
  myWarehouses: WarehouseRto[]
}

export const useUserSession = defineStore('userSession', () => {
  const sessionStorage = useStorage('sessionData', '')
  const selectedWarehouseCode = useStorage('selectedWarehouseCode', '')
  const tabSessionId = useSessionStorage('tabSessionId', '')
  const sessionId = useSessionStorage('sessionId', '')

  const socketStore = useSocketStore()

  if (tabSessionId.value === '' || tabSessionId.value === 'undefined') {
    // New Session.
    tabSessionId.value = generateUUID()
  }

  const sessionData = computed(() => {
    let session: ISessionInfo | undefined
    if (sessionStorage.value == '') {
      return session
    }

    try {
      session = JSON.parse(sessionStorage.value)
    } catch {
      console.error('Invalid session data.')
    }
    return session
  })

  const selectedWarehouse = computed(() => {
    let warehouse: WarehouseRto | undefined
    try {
      if (sessionData.value) {
        warehouse = sessionData.value.myWarehouses.find((e) => e.warehouseCode.toLowerCase() == selectedWarehouseCode.value)
      }
    } catch { }
    return warehouse
  })

  function getDefaultWarehouse(defaultWarehouseId: number) {
    let warehouse: WarehouseRto | undefined
    //console.log('getDefaultWarehouse', defaultWarehouseId, sessionData.value)
    if (sessionData.value) {
      warehouse = sessionData.value.myWarehouses.find((e) => e.warehouseId === defaultWarehouseId)
      if (!warehouse) {
        warehouse = sessionData.value.myWarehouses[0]
      }
    }
    return warehouse
  }

  function setWarehouse(warehouseCode: string) {
    let warehouse: WarehouseRto | undefined
    if (sessionData.value?.myWarehouses && warehouseCode) {
      warehouse = sessionData.value.myWarehouses.find((e) => e.warehouseCode.toLowerCase() === warehouseCode.toLowerCase())
      if (warehouse) {
        //localStorage.setItem('selectedWarehouseCode', warehouseCode.toLowerCase())
        selectedWarehouseCode.value = warehouseCode.toLowerCase()
        SessionModel.subscribeToWarehouse(tabSessionId.value, warehouse.warehouseId)
        return true
      }
    }
    return false
  }

  function setUserSession(newSession: ISessionInfo) {
    //localStorage.setItem('sessionData', JSON.stringify(newSession))
    sessionStorage.value = JSON.stringify(newSession)
    sessionId.value = newSession.sessionId
    if (tracker) {
      const trackerInstance = tracker
      trackerInstance?.setUserID(newSession.userName)
    }
    if (socketStore.isConnected == false) {
      connectWsSocket()
    }
  }

  async function clearSesionLocal() {
    sessionId.value = ''
  }

  async function getLocalSessionId() {
    return sessionId.value
  }

  async function logoutUser() {
    clearSesionLocal()
    await SessionModel.logoutUser()
    sessionStorage.value = null
    selectedWarehouseCode.value = null
    socketStore.setConfig(false)
    await socketStore.disconnect()
    console.info('User logged out.')
  }

  const busWebsocket = useEventBus<any>('socketStore')
  busWebsocket.on((name, payload) => {
    const jObj = JSON.parse(payload)
    if (jObj.opCode === 'connected') {
      if (selectedWarehouse.value) {
        SessionModel.subscribeToWarehouse(tabSessionId.value, selectedWarehouse.value?.warehouseId)
      }
    }
  })

  async function connectWsSocket() {
    socketStore.setSocketURL(`${import.meta.env.VITE_API_BASE_WSS}/v1/ws?sid=${sessionData.value?.sessionId}&connId=${tabSessionId.value}`)
    if (socketStore.isConnected) {
      await socketStore.disconnect()
    }
    socketStore.setConfig(true)
    await socketStore.connect()
    //socketStore.wsInit(`${import.meta.env.VITE_API_BASE_WSS}/v1/ws?sid=${sessionData.value?.sessionId}&connId=${tabSessionId.value}`)
    //socketStore.instance?.subscribe('notify', msgCb)
  }

  return {
    sessionData,
    tabSessionId,
    selectedWarehouse,
    getLocalSessionId,
    clearSesionLocal,
    getDefaultWarehouse,
    setWarehouse,
    logoutUser,
    setUserSession,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
