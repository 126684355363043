<script setup lang="ts">
import { initDarkmode } from '/@src/stores/darkmode'
import { App as CapacitorApp } from '@capacitor/app'
import { useUserSession } from '/@src/stores/userSession'
import { useNotyf } from './composable/useNotyf'
import { getCapacitorPlatformName } from './utils/common'

const userSession = useUserSession()
const notif = useNotyf()
const route = useRoute()
const router = useRouter()

// This is the global app setup function

/**
 * Initialize the darkmode watcher
 *
 * @see /@src/stores/darkmode
 */
initDarkmode()

// Websocket Event Bus
const busSocketStore = useEventBus<any>('socketStore')
busSocketStore.on(async (eventName: string, payload: string) => {
  if (eventName === 'message') {
    let msg = JSON.parse(payload)
    if (msg.opCode === 'app:reload' && msg.payload.userName === userSession.sessionData?.userName) {
      // Reload the page
      if (import.meta.env.VITE_APP_VERSION !== msg.payload.appVersion) {
        location.reload()
      } else if (msg.payload.appVersion == '0.0.0') {
        location.reload()
      }
    } else if (msg.opCode === 'app:reload' && msg.payload.userType == userSession.sessionData?.userType) {
      // Reload the page
      if (import.meta.env.VITE_APP_VERSION !== msg.payload.appVersion) {
        location.reload()
      } else if (msg.payload.appVersion == '0.0.0') {
        location.reload()
      }
    }
  }
})

onMounted(() => {
  //notif.blue(route.name)
  if (getCapacitorPlatformName() === 'android') {
    // Handle back button on Android
    CapacitorApp.addListener('backButton', async ({ canGoBack }) => {
      //notif.info(`canGoBack: ${canGoBack} - ${route.name}`)
      if (!canGoBack) {
        if (userSession.sessionData?.sessionId) {
          notif.error(`You are not allowed to go back. Please logout first.`)
        } else {
          await CapacitorApp.exitApp()
        }
      } else {
        if (route.name == '/') {
          // Exit the app if the user is on the login page
          await CapacitorApp.exitApp()
        } else {
          router.back()
        }
      }
    })
  }
})

onUnmounted(async () => {
  if (getCapacitorPlatformName() === 'android') {
    await CapacitorApp.removeAllListeners()
  }
})
</script>

<template>
  <div>
    <Suspense>
      <RouterView v-slot="{ Component }">
        <Transition name="fade-slow" mode="out-in">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </Suspense>
    <ClientOnly>
      <VReloadPrompt app-name="BusyDo WMS" />
    </ClientOnly>
    <!-- Dialogs Wrapper for ConfirmDialog -->
    <DialogsWrapper />
  </div>
</template>
