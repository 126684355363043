import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'

/**
 * WebSocket Store
 */

export const useSocketStore = defineStore('socketStore', () => {
  const socketURL = ref('')
  const socket = ref<WebSocket | null>(null)
  const connected = ref(false)
  const lastPong = ref<number | null>(null)
  const reconnectInterval = ref<ReturnType<typeof setInterval> | null>(null)
  const pingInterval = ref<ReturnType<typeof setInterval> | null>(null)
  const busSocketStore = useEventBus<any>('socketStore')
  const shouldReconnect = ref(true)

  const isConnected = computed(() => {
    return connected.value
  })

  const setSocketURL = (url: string) => {
    socketURL.value = url
  }

  const setConfig = (reconnect: boolean) => {
    shouldReconnect.value = reconnect
  }

  const connect = async () => {
    if (socket.value) return
    stopReconnectInterval()
    stopPingInterval()

    socket.value = new WebSocket(socketURL.value)
    socket.value.onopen = () => {
      onOpen()
    }
    socket.value.onclose = () => {
      onClose()
    }
    socket.value.onmessage = (e) => {
      onMessage(e)
    }
    socket.value.onerror = (e) => {
      onError(e)
    }
  }

  const disconnect = async () => {
    if (!socket.value) return
    socket.value.close()
    socket.value = null
  }

  const onOpen = () => {
    lastPong.value = Date.now()
    connected.value = true
    //console.log('WebSocket connected')
    startPingInterval()
    startReconnectInterval()
  }

  const onMessage = (e: any) => {
    const message = e.data
    lastPong.value = Date.now()
    if (message != 'pong') {
      //console.log('WebSocket message received:', message)
      busSocketStore.emit('message', message)
    }
  }

  const onClose = () => {
    connected.value = false
    //console.log('WebSocket disconnected')
    socket.value = null
    stopPingInterval()
    stopReconnectInterval()
    if (socketURL.value === '' || shouldReconnect.value === false) return
    startReconnectInterval()
  }

  const onError = (e: any) => {
    console.error('WebSocket error:', e)
    disconnect()
  }

  const sendMessage = async (message: string) => {
    if (!socket.value) return false
    if (connected.value) {
      socket.value.send(message)
      return true
    } else {
      console.error('WebSocket is not connected')
      return false
    }
  }

  const startPingInterval = () => {
    if (pingInterval.value) return
    pingInterval.value = setInterval(() => {
      if (connected.value) {
        socket.value?.send('ping')
        const currentTime = Date.now()

        if (lastPong.value && currentTime - lastPong.value > 85000) {
          //console.error('WebSocket connection lost')
          disconnect()
        }
      }
    }, 15000)
  }

  const stopPingInterval = () => {
    if (!pingInterval.value) return
    clearInterval(pingInterval.value)
    pingInterval.value = null
  }

  const startReconnectInterval = () => {
    if (reconnectInterval.value) return

    reconnectInterval.value = setInterval(() => {
      if (!connected.value) {
        connect()
      }
    }, 10000)
  }

  const stopReconnectInterval = () => {
    if (!reconnectInterval.value) return
    clearInterval(reconnectInterval.value)
    reconnectInterval.value = null
  }

  return {
    socketURL,
    isConnected,
    setConfig,
    connect,
    disconnect,
    sendMessage,
    setSocketURL,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSocketStore, import.meta.hot))
}
