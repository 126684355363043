import { START_LOCATION } from 'vue-router'
import { useNotyf } from '../composable/useNotyf'
import { definePlugin } from '/@src/app'
import { useUserSession } from '/@src/stores/userSession'

/**
 * Here we are setting up two router navigation guards
 * (note that we can have multiple guards in multiple plugins)
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default definePlugin(async ({ router, api, pinia }) => {
  //const userSession = useUserSession(pinia)

  // 1. Check token validity at app startup
  /*   if (userSession.sessionData) {
    try {
      // Do api request call to retreive user profile.
      const loginApiResp = await api.get('/session')
      if (loginApiResp.status == 200) {
        userSession.setUserSession(loginApiResp.data)
      } else {
        // delete stored token if it fails
        userSession.logoutUser()
      }
    } catch (err) {
      // delete stored token if it fails
      userSession.logoutUser()
    }
  } */

  router.beforeEach(async (to, from, next) => {
    const userSession = useUserSession()
    const notyf = useNotyf()

    if (userSession.sessionData?.sessionId) {
      // Check if the UserType is allowed to access the route
      const requiresUserType = to.meta.requiresUserType as string[] | undefined
      if (to.meta.requiresAuth && requiresUserType && !requiresUserType.includes(userSession.sessionData.userType)) {
        notyf.error({ message: 'Access Denied', duration: 5000 })
        // Redirect to Login
        next({ path: '/' })
        return
      }

      const sessionId = await userSession.getLocalSessionId()
      if (from != START_LOCATION && sessionId != '' && to.name === '/') {
        notyf.error({ message: 'You cant go back without logging out.', duration: 5000 })
        next(false)
        return
      }
    }


    if (from === START_LOCATION && to.params['routeWarehouseCode']) {
      //console.log(`New URL Loaded Manually`)
      if (to.meta.requiresAuth) {
        const isWarehouseSaved = userSession.setWarehouse(to.params['routeWarehouseCode'].toString())
        if (!isWarehouseSaved) {
          notyf.error({ message: 'Warehouse Access Denied', duration: 5000 })
          // No Warehouse Selected. Redirect to Login
          next({ path: '/' })
          return
        }
      }
    }

    if (!to.params['routeWarehouseCode']) {
      if (userSession.selectedWarehouse) {
        to.params['routeWarehouseCode'] = userSession.selectedWarehouse.warehouseCode.toLowerCase()
      } else if (to.meta.requiresAuth) {
        notyf.error({ message: 'Warehouse Access Denied', duration: 5000 })
        // No Warehouse Selected. Redirect to Login
        next({ path: '/' })
        return
      }
    }

    if (from === START_LOCATION && userSession.sessionData?.sessionId) {
      // 1. If the name is not set, it means we are navigating to the first page
      // and we are logged in, so we should check user information from the server
      const loginApiResp = await api.get('/v1/login')
      if (loginApiResp.status == 200) {
        userSession.setUserSession(loginApiResp.data)
        if (to.name === '/') {
          // Login Page Request. Redirect Directly to Dashboard if logged in.
          if (userSession.sessionData?.sessionId && userSession.selectedWarehouse) {
            if (userSession.sessionData?.userType == 'Picker') {
              next({
                name: '/w/[routeWarehouseCode]/picker/handheldPicking',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else if (userSession.sessionData?.userType == 'Worker') {
              next({
                name: '/w/[routeWarehouseCode]/worker/receiving/receiverList',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else if (userSession.sessionData?.userType == 'Driver') {
              next({
                name: '/w/[routeWarehouseCode]/driver/route/routeList',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else {
              next({
                name: '/w/[routeWarehouseCode]/dashboard',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            }
          }
        } else {
          notyf.success(`Welcome back, ${loginApiResp.data.name}`)
        }
      } else if (loginApiResp.status == 401) {
        userSession.logoutUser()
        notyf.error({ message: 'Your session is no longer valid', duration: 5000 })

        if (to.meta.requiresAuth) {
          // redirect the user somewhere
          next({
            // Will follow the redirection set in /@src/pages/auth/index.vue
            path: '/',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
          })
          return
        }
      } else {
        // Unknown Login Status. Redirect to Dashboard if locally logged-in.
        if (to.name === '/') {
          // Login Page Request. Redirect Directly to Dashboard if logged in.
          if (userSession.sessionData?.sessionId && userSession.selectedWarehouse) {
            if (userSession.sessionData?.userType == 'Picker') {
              next({
                name: '/w/[routeWarehouseCode]/picker/handheldPicking',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else if (userSession.sessionData?.userType == 'Worker') {
              next({
                name: '/w/[routeWarehouseCode]/worker/receiving/receiverList',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else if (userSession.sessionData?.userType == 'Driver') {
              next({
                name: '/w/[routeWarehouseCode]/driver/route/routeList',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            } else {
              next({
                name: '/w/[routeWarehouseCode]/dashboard',
                params: { routeWarehouseCode: userSession.selectedWarehouse?.warehouseCode.toLowerCase() },
              })
              return
            }
          }
        }
      }
    } else if (to.meta.requiresAuth && !userSession.sessionData?.sessionId) {
      // 2. If the requires auth via requiresAuth meta, check if user is logged in
      // if not, redirect to login page.
      notyf.error({ message: 'Your session is no longer valid', duration: 5000 })
      next({
        // Will follow the redirection set in /@src/pages/auth/index.vue
        path: '/',
        // save the location we were at to come back later
        query: { redirect: to.fullPath },
      })
      return
    }

    next()
  })
})
