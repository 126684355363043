import { createApp as createClientApp } from 'vue'

import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'
import { createRouter } from './router'
import AppRoot from './AppRoot.vue'
import './styles'
import { createApi } from '/@src/composable/useApi'
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import * as ConfirmDialog from 'vuejs-confirm-dialog'


export type AppRootContext = Awaited<ReturnType<typeof createApp>>
export type VueroPlugin = (vuero: AppRootContext) => void | Promise<void>

export const tracker = new Tracker({
  projectKey: import.meta.env.VITE_TRACKER_KEY,
  ingestPoint: "https://ingest.lnpl.net/ingest",
  resourceBaseHref: import.meta.env.VITE_APP_URL,
  revID: import.meta.env.VITE_APP_VERSION,
  obscureTextEmails: false,
  obscureTextNumbers: false,
  obscureInputEmails: false,
  obscureInputDates: false,
  obscureInputNumbers: false,
  defaultInputMode: 0,
  connAttemptCount: 225,
  network: {
    sessionTokenHeader: false, // Don't send session token in headers
    ignoreHeaders: false, // Capture all headers
    failuresOnly: false, // Capture only failed requests
    captureInIframes: false, // Capture requests in iframes
    capturePayload: true // Capture HTTP payload
  },
});
if (import.meta.env.VITE_LOG_SESSION_RECORDING) {
  tracker.start();
  tracker.use(trackerAssist());
}


const plugins = import.meta.glob<{ default: VueroPlugin }>('./plugins/*.ts', {
  eager: true,
})

// this is a helper function to define plugins with autocompletion
export function definePlugin(plugin: VueroPlugin) {
  return plugin
}

export async function createApp() {
  const app = createClientApp(AppRoot)
  //app.provide('tracker', tracker)

  const router = createRouter()
  const api = createApi()

  const head = createHead()
  app.use(head)

  const pinia = createPinia()
  app.use(pinia)

  const webapp = {
    app,
    api,
    router,
    head,
    pinia,
  }

  app.provide('vuero', webapp)

  for (const path in plugins) {
    try {
      const { default: plugin } = plugins[path]
      await plugin(webapp)
    } catch (error) {
      console.error(`Error while loading plugin "${path}".`)
      console.error(error)
    }
  }

  // Register the confirm dialog plugin
  app.use(ConfirmDialog)
  // use router after plugin registration, so we can register navigation guards
  app.use(webapp.router)

  return webapp
}
