import moment from 'moment-timezone'
import { Capacitor } from '@capacitor/core'

/**
 * Generate Unique UUID.
 */

export function generateUUID() {
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now() //use high-precision timer if available
  }
  const newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })

  return newGuid
}

/**
 * Convert Date to String
 * @param value Date Value
 */
export function dateDefaultFormat(value: any): string {
  if (value) {
    return moment(value).format('MMM DD yyyy hh:mm a')
  } else {
    return value
  }
}

/**
 * Date Format for Calendar
 * @param value Date Value in String
 */
export function dateCalendarFormat(value: string): string {
  if (value) {
    return moment(value).calendar()
    //const offset = moment().utcOffset()
    //return moment.utc(value).utcOffset(offset).calendar()
  } else {
    return value
  }
}

/**
 * Convert Date Object to String with Format
 * @param value Date Value in Date
 * @param format Date Format in String
 */
export function convertDateToString(value: Date, format: string): string {
  if (value) {
    return moment(value).format(format)
  } else {
    return value
  }
}

/**
 * Convert Date to String
 */
export function formatDateWithoutTimezoneConversion(date: Date, format: string): string {
  const utcDate = moment.utc(date)
  return utcDate.format(format)
}

/**
 * Convert Date String to String from Current Format to Target Format
 * @param dateString
 * @param currentFormat
 * @param targetFormat
 */
export function convertDateStringFormat(dateString: string, currentFormat: string, targetFormat: string) {
  const date = moment(dateString, currentFormat)
  return date.format(targetFormat)
}

/**
 * Convert Date String to Date Object
 * @param dateString Date in String
 */
export function convertDateToExactUtc(dateString: string): Date {
  const dateParts = dateString.split('-') as unknown as number[] // split the string into year, month, and day parts
  const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]) // create a new Date object using the parts
  return date
}

/**
 * Format Number with Decimal Digits
 * @param value Input Number
 * @param digits Decimal Digits Count
 */
export function decimalFormat(value: number, digits?: number): number {
  if (digits === undefined) {
    digits = 2
  }
  const v = Number(value.toFixed(digits))
  return v
}

/**
 * Remove Decimal Digits and Return String with comma seperated value
 * @param value Input Number
 */
export function decimalFormatWithComma(value: number): string {
  const v = Number(value.toFixed(0))
  return v.toLocaleString()
}

// Get User Profile Avatar URL
export function getFullAvatarUrl(avatarFile: string): string {
  const avatar = import.meta.env.VITE_API_BASE_URL + '/files/uploads/' + avatarFile
  return avatar
}

/**
 * Check Capictor Platform Name is android, ios or web
 * @returns string
 */

export function getCapacitorPlatformName(): string {
  return Capacitor.getPlatform()
}

/**
 * Error Modal Interface Types
 */

export interface ErrorRow {
  id: string
  message: string
}
