/**
 * This is your client vue app entrypoint
 * Its loaded because it is referenced in the vite
 * entrypoint file (index.html located at the root of this project)
 *
 * External css/js files will be loaded as dependencies.
 * You may want to check the vite configuration.
 * Some plugins will register virtual components or lazyload other for us.
 *
 * @see /index.html
 * @see /vite.config.ts
 * @see /app.ts
 * @see /plugins/*.ts
 */
import { createApp } from './app'

// Catch all errors and log them to the API.
window.onunhandledrejection = async function (event) {
  const postJsonData = {
    "type": "unhandledrejection",
    "msg": event.reason.message,
    "stack": event.reason.stack,
    "url": window.location.href,
    "date": new Date(),
  };
  // Send error to API.
  fetch(`${import.meta.env.VITE_API_BASE_URL}/log/error/unhandledrejection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postJsonData),
  })
};

window.onerror = async function (msg, source, lineNo, columnNo, error) {
  const postJsonData = {
    "type": "onerror",
    "msg": msg,
    "source": source,
    "lineNo": lineNo,
    "columnNo": columnNo,
    "error": error,
    "url": window.location.href,
    "date": new Date(),
  };
  // Send error to API.
  fetch(`${import.meta.env.VITE_API_BASE_URL}/log/error/exception`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postJsonData),
  })
};

/**
 * We create our app and mount it when it is ready
 *
 * @see /@src/app.ts for more detailed informations
 */
createApp().then(async (app) => {
  // @ts-ignore
  const initialState = window.__app__
  // restore pinia state from SSR if any
  if (typeof initialState?.pinia === 'object') {
    app.pinia.state.value = initialState.pinia
  }

  // wait for the app to be ready
  await app.router.isReady()

  // finaly mount the app to the DOM
  app.app.mount('#app')
})

/* CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp()
  } else {
    window.history.back()
  }
})
 */