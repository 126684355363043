import { useUserSession, type ISessionInfo } from '../stores/userSession'
import { useApi } from '/@src/composable/useApi'

export interface ILoginPto {
  username: string
  password: string
  rememberMe: boolean
}

export interface IResp {
  status: number
  msg: string
  data?: ISessionInfo
}

const apiVersion = import.meta.env.VITE_API_VERSION
export async function loginUser(loginPto: ILoginPto): Promise<IResp> {
  const api = useApi()
  const apiResp = await api.post(`/${apiVersion}/login`, loginPto)
  if (apiResp.status == 200) {
    return { status: apiResp.status, msg: apiResp.data.msg, data: apiResp.data as ISessionInfo }
  } else {
    return { status: apiResp.status, msg: apiResp.data.msg }
  }
}

export async function logoutUser(): Promise<IResp> {
  const api = useApi()
  await api.get(`/${apiVersion}/logout`)
  return { status: 200, msg: 'You have been succussfully logged out' }
}

export async function getSessionInfo(): Promise<IResp> {
  const api = useApi()
  const apiResp = await api.get(`/${apiVersion}/login`)
  if (apiResp.status == 200) {
    return { status: apiResp.status, msg: apiResp.data.msg, data: apiResp.data as ISessionInfo }
  } else {
    return { status: apiResp.status, msg: apiResp.data.msg }
  }
}

export async function subscribeToWarehouse(connId: string, warehouseId: number): Promise<IResp> {
  const api = useApi()
  const apiResp = await api.get(`/${apiVersion}/ws/subscribe-warehouse`, { params: { connId: connId, warehouseId: warehouseId } })
  if (apiResp.status == 200) {
    return { status: apiResp.status, msg: apiResp.data.msg }
  } else {
    return { status: apiResp.status, msg: apiResp.data.msg }
  }
}

// Set Profile Image
export async function setProfileImage(file: File): Promise<IResp> {
  const api = useApi()
  let formData = new FormData()
  formData.append('file', file)
  const apiResp = await api.post(`/${apiVersion}/file-upload?type=profile`, formData)
  if (apiResp.status == 200) {
    // Set the profile image for the user.
    formData = new FormData()
    formData.append('fileId', apiResp.data.fileId)
    const apiResp2 = await api.post(`/${apiVersion}/login/setProfileImage`, formData)
    if (apiResp2.status == 200) {
      const userSession = useUserSession()
      if (userSession.sessionData) {
        const loginApiResp = await api.get('/v1/login')
        if (loginApiResp.status == 200) {
          userSession.setUserSession(loginApiResp.data)
        }
      }
      return { status: apiResp2.status, msg: apiResp2.data.msg }
    } else {
      return { status: apiResp2.status, msg: apiResp2.data.msg }
    }
  } else {
    return { status: apiResp.status, msg: apiResp.data.msg }
  }
}