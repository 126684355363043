import axios, { type RawAxiosRequestHeaders, type AxiosInstance } from 'axios'
import { useUserSession } from '/@src/stores/userSession'
//import { useNotyf } from '/@src/composable/useNotyf'

export interface OpRespCollection<T> {
  status: number
  msg: string
  data?: RecordCollection<T>
}

export interface OpResp<T> {
  status: number
  msg: string
  errors?: OpError[]
  data?: T
}

export interface RecordCollection<T> {
  rowMatched: number
  rowOffset: number
  rowLimit: number
  records: T | undefined
}

export interface OpError {
  requestId: string
  code: string
  message: string
  description: string
  module: string
}

let api: AxiosInstance
//const notyf = useNotyf()

export function createApi() {
  // Here we set the base URL for all requests made to the api
  api = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
  })

  // We set an interceptor for each request to
  // include Session Token to the request if user is logged in
  api.interceptors.request.use((config) => {
    const userSession = useUserSession()
    if (userSession.sessionData?.sessionId) {
      config.headers = {
        ...((config.headers as RawAxiosRequestHeaders) ?? {}),
        'Session-Id': userSession.sessionData?.sessionId,
        'Tab-Session-Id': userSession.tabSessionId,
      }
    }

    return config
  })

  // Add a response interceptor
  api.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response && error.response.status != 0) {
        // .NET Core Bad Request Error
        if (error.response.status === 400) {
          return { status: 400, data: { msg: error.response.data.title } }
        } else if (error.response.status === 401) {
          // Session Invalid
          handleLogout()
        }
        return error.response
      } else {
        return { status: 0, data: { msg: error.message } }
      }
    }
  )

  return api
}

export function useApi() {
  if (!api) {
    createApi()
  }
  return api
}

const handleLogout = async () => {
  const userSession = useUserSession()
  userSession.logoutUser()
  // Bug with 'info' message if used here.
  //notyf.error({ message: 'Your session is no longer valid', duration: 5000 })
}
