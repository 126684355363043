import { _HasDataLoaderMeta, _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/config',
    name: '/config',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/config.vue'),
    children: [
      {
        path: 'smtpAccounts',
        name: '/config/smtpAccounts',
        component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/config/smtpAccounts.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresUserType": [
        "Admin"
      ]
    }
  },
  {
    path: '/test',
    name: '/test',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/test.vue'),
    /* no children */
  },
  {
    path: '/users',
    name: '/users',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/users.vue'),
    /* no children */
    meta: {
      "requiresAuth": true,
      "requiresUserType": [
        "Admin"
      ]
    }
  },
  {
    path: '/w',
    /* internal name: '/w' */
    /* no component */
    children: [
      {
        path: ':routeWarehouseCode',
        name: '/w/[routeWarehouseCode]',
        component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode].vue'),
        children: [
          {
            path: '',
            name: '/w/[routeWarehouseCode]/',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/index.vue'),
            /* no children */
          },
          {
            path: 'config',
            /* internal name: '/w/[routeWarehouseCode]/config' */
            /* no component */
            children: [
              {
                path: 'adjReasons',
                name: '/w/[routeWarehouseCode]/config/adjReasons',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/adjReasons.vue'),
                /* no children */
              },
              {
                path: 'aisles',
                name: '/w/[routeWarehouseCode]/config/aisles',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/aisles.vue'),
                /* no children */
              },
              {
                path: 'area',
                name: '/w/[routeWarehouseCode]/config/area',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/area.vue'),
                /* no children */
              },
              {
                path: 'customerPalletConfig',
                name: '/w/[routeWarehouseCode]/config/customerPalletConfig',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/customerPalletConfig.vue'),
                /* no children */
              },
              {
                path: 'epodCancelReasons',
                name: '/w/[routeWarehouseCode]/config/epodCancelReasons',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/epodCancelReasons.vue'),
                /* no children */
              },
              {
                path: 'equipment',
                name: '/w/[routeWarehouseCode]/config/equipment',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/equipment.vue'),
                /* no children */
              },
              {
                path: 'inventoryStatus',
                name: '/w/[routeWarehouseCode]/config/inventoryStatus',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/inventoryStatus.vue'),
                /* no children */
              },
              {
                path: 'label',
                name: '/w/[routeWarehouseCode]/config/label',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/label.vue'),
                /* no children */
              },
              {
                path: 'location',
                name: '/w/[routeWarehouseCode]/config/location',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/location.vue'),
                /* no children */
              },
              {
                path: 'locationSize',
                name: '/w/[routeWarehouseCode]/config/locationSize',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/locationSize.vue'),
                /* no children */
              },
              {
                path: 'palletType',
                name: '/w/[routeWarehouseCode]/config/palletType',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/palletType.vue'),
                /* no children */
              },
              {
                path: 'pickZone',
                name: '/w/[routeWarehouseCode]/config/pickZone',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/pickZone.vue'),
                /* no children */
              },
              {
                path: 'printer',
                name: '/w/[routeWarehouseCode]/config/printer',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/printer.vue'),
                /* no children */
              },
              {
                path: 'uom',
                name: '/w/[routeWarehouseCode]/config/uom',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/config/uom.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'dashboard',
            name: '/w/[routeWarehouseCode]/dashboard',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/dashboard.vue'),
            /* no children */
          },
          {
            path: 'driver',
            /* internal name: '/w/[routeWarehouseCode]/driver' */
            /* no component */
            children: [
              {
                path: 'route',
                name: '/w/[routeWarehouseCode]/driver/route',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/driver/route.vue'),
                children: [
                  {
                    path: 'palletDetail',
                    name: '/w/[routeWarehouseCode]/driver/route/palletDetail',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/driver/route/palletDetail.vue'),
                    /* no children */
                  },
                  {
                    path: 'routeDetail',
                    name: '/w/[routeWarehouseCode]/driver/route/routeDetail',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/driver/route/routeDetail.vue'),
                    /* no children */
                  },
                  {
                    path: 'routeList',
                    name: '/w/[routeWarehouseCode]/driver/route/routeList',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/driver/route/routeList.vue'),
                    /* no children */
                  },
                  {
                    path: 'stopDetail',
                    name: '/w/[routeWarehouseCode]/driver/route/stopDetail',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/driver/route/stopDetail.vue'),
                    /* no children */
                  }
                ],
                meta: {
                  "requiresUserType": [
                    "Driver"
                  ]
                }
              }
            ],
          },
          {
            path: 'inventory',
            /* internal name: '/w/[routeWarehouseCode]/inventory' */
            /* no component */
            children: [
              {
                path: '',
                name: '/w/[routeWarehouseCode]/inventory/',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/inventory/index.vue'),
                /* no children */
              },
              {
                path: 'pallet',
                name: '/w/[routeWarehouseCode]/inventory/pallet',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/inventory/pallet.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'master',
            /* internal name: '/w/[routeWarehouseCode]/master' */
            /* no component */
            children: [
              {
                path: 'customer',
                name: '/w/[routeWarehouseCode]/master/customer',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/customer.vue'),
                /* no children */
              },
              {
                path: 'product',
                name: '/w/[routeWarehouseCode]/master/product',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/product.vue'),
                children: [
                  {
                    path: '',
                    name: '/w/[routeWarehouseCode]/master/product/',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/product/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'entry',
                    name: '/w/[routeWarehouseCode]/master/product/entry',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/product/entry.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'productType',
                name: '/w/[routeWarehouseCode]/master/productType',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/productType.vue'),
                /* no children */
              },
              {
                path: 'shipperRoute',
                /* internal name: '/w/[routeWarehouseCode]/master/shipperRoute' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '/w/[routeWarehouseCode]/master/shipperRoute/',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/shipperRoute/index.vue'),
                    /* no children */
                  },
                  {
                    path: 'stops',
                    name: '/w/[routeWarehouseCode]/master/shipperRoute/stops',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/shipperRoute/stops.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'supplier',
                name: '/w/[routeWarehouseCode]/master/supplier',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/master/supplier.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'picker',
            name: '/w/[routeWarehouseCode]/picker',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/picker.vue'),
            children: [
              {
                path: 'handheldPicking',
                name: '/w/[routeWarehouseCode]/picker/handheldPicking',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/picker/handheldPicking.vue'),
                /* no children */
              },
              {
                path: 'handheldPicking1',
                name: '/w/[routeWarehouseCode]/picker/handheldPicking1',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/picker/handheldPicking1.vue'),
                /* no children */
              }
            ],
            meta: {
              "requiresUserType": [
                "Picker"
              ]
            }
          },
          {
            path: 'receiving',
            name: '/w/[routeWarehouseCode]/receiving',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/receiving.vue'),
            children: [
              {
                path: 'receiverDetail',
                name: '/w/[routeWarehouseCode]/receiving/receiverDetail',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/receiving/receiverDetail.vue'),
                /* no children */
              },
              {
                path: 'receiverList',
                name: '/w/[routeWarehouseCode]/receiving/receiverList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/receiving/receiverList.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'shipping',
            name: '/w/[routeWarehouseCode]/shipping',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping.vue'),
            children: [
              {
                path: 'afsErpVarianceList',
                name: '/w/[routeWarehouseCode]/shipping/afsErpVarianceList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/afsErpVarianceList.vue'),
                /* no children */
              },
              {
                path: 'printCsvLabels',
                name: '/w/[routeWarehouseCode]/shipping/printCsvLabels',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/printCsvLabels.vue'),
                /* no children */
              },
              {
                path: 'shippedInventory',
                name: '/w/[routeWarehouseCode]/shipping/shippedInventory',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/shippedInventory.vue'),
                /* no children */
              },
              {
                path: 'shipperDetail',
                name: '/w/[routeWarehouseCode]/shipping/shipperDetail',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/shipperDetail.vue'),
                /* no children */
              },
              {
                path: 'shipperList',
                name: '/w/[routeWarehouseCode]/shipping/shipperList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/shipperList.vue'),
                /* no children */
              },
              {
                path: 'waveDetail',
                name: '/w/[routeWarehouseCode]/shipping/waveDetail',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/waveDetail.vue'),
                /* no children */
              },
              {
                path: 'waveList',
                name: '/w/[routeWarehouseCode]/shipping/waveList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/shipping/waveList.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'work',
            name: '/w/[routeWarehouseCode]/work',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/work.vue'),
            children: [
              {
                path: 'pickAssignmentDetail',
                name: '/w/[routeWarehouseCode]/work/pickAssignmentDetail',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/work/pickAssignmentDetail.vue'),
                /* no children */
              },
              {
                path: 'pickAssignmentList',
                name: '/w/[routeWarehouseCode]/work/pickAssignmentList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/work/pickAssignmentList.vue'),
                /* no children */
              },
              {
                path: 'replenishList',
                name: '/w/[routeWarehouseCode]/work/replenishList',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/work/replenishList.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'worker',
            name: '/w/[routeWarehouseCode]/worker',
            component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker.vue'),
            children: [
              {
                path: 'palletMove',
                name: '/w/[routeWarehouseCode]/worker/palletMove',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/palletMove.vue'),
                /* no children */
              },
              {
                path: 'receiving',
                name: '/w/[routeWarehouseCode]/worker/receiving',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving.vue'),
                children: [
                  {
                    path: 'picking',
                    name: '/w/[routeWarehouseCode]/worker/receiving/picking',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving/picking.vue'),
                    /* no children */
                  },
                  {
                    path: 'putaway',
                    name: '/w/[routeWarehouseCode]/worker/receiving/putaway',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving/putaway.vue'),
                    /* no children */
                  },
                  {
                    path: 'receiverDetail',
                    name: '/w/[routeWarehouseCode]/worker/receiving/receiverDetail',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving/receiverDetail.vue'),
                    /* no children */
                  },
                  {
                    path: 'receiverList',
                    name: '/w/[routeWarehouseCode]/worker/receiving/receiverList',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving/receiverList.vue'),
                    /* no children */
                  },
                  {
                    path: 'scanning',
                    name: '/w/[routeWarehouseCode]/worker/receiving/scanning',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/receiving/scanning.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'replenishment',
                name: '/w/[routeWarehouseCode]/worker/replenishment',
                component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/replenishment.vue'),
                children: [
                  {
                    path: 'replenishList',
                    name: '/w/[routeWarehouseCode]/worker/replenishment/replenishList',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/replenishment/replenishList.vue'),
                    /* no children */
                  },
                  {
                    path: 'replenishTask',
                    name: '/w/[routeWarehouseCode]/worker/replenishment/replenishTask',
                    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/w/[routeWarehouseCode]/worker/replenishment/replenishTask.vue'),
                    /* no children */
                  }
                ],
              }
            ],
            meta: {
              "requiresUserType": [
                "Worker"
              ]
            }
          }
        ],
        meta: {
          "requiresAuth": true,
          "requiresUserType": [
            "Manager",
            "Admin"
          ]
        }
      }
    ],
  },
  {
    path: '/warehouses',
    name: '/warehouses',
    component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/warehouses.vue'),
    children: [
      {
        path: '',
        name: '/warehouses/',
        component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/warehouses/index.vue'),
        /* no children */
      },
      {
        path: 'users',
        name: '/warehouses/users',
        component: () => import('K:/source/GitHub/BusyDo/WMS-FE1/src/pages/warehouses/users.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "requiresUserType": [
        "Admin"
      ]
    }
  }
]
